<template>
  <Layout :popInfo="popInfo">
    <div class="paymentResult">
      <div v-if="info.result === 'SUCCESS'">
        <img src="../assets/success.png" alt="" />
        <h3>Payment Successfully</h3>
      </div>
      <div v-else>
        <img src="../assets/fail.png" alt="" />
        <h3>Payment failed</h3>
      </div>
      <hr />
      <p>
        <span>Amount</span>
        <span>₹ {{ amount }}</span>
      </p>
      <p>
        <span style="margin-bottom: 0">Payment Method</span>
        <span>{{ type }}</span>
      </p>
      <hr />
      <div v-if="info.result === 'SUCCESS'">
        <p v-if="upiRefId">UPI Ref ID: {{ upiRefId }}</p>
        <p v-else>Payment ID: {{ paymentId }}</p>
      </div>
      <div class="bottom">
        <button @click="close">Confirm</button>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/layout/index.vue";
import { useRoute } from "vue-router";
import { reactive } from "vue";

let route = useRoute();
let result = route.query.result;
let type = route.query.type;
let amount = route.query.amount;
let paymentId = route.query.paymentId;
let upiRefId = route.query.upiRefId;
let redirectLink = sessionStorage.getItem("redirectLink");
let info = reactive({
  result: result,
});

const popInfo = {
  title: "Close？",
  content: "Are you sure you want to exit？",
};

let close = () => {
  if (redirectLink === "about:blank") {
    window.close();
  } else {
    window.location.href = redirectLink;
  }
};
</script>

<style scoped lang="scss" type="text/scss">
.paymentResult {
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    width: 25%;
  }

  h3 {
    margin: 5% 0;
  }

  p {
    font-size: 1rem;
    color: #666666;
    overflow: hidden;

    span:first-child {
      float: left;
      margin-bottom: 10%;
    }

    span:last-child {
      max-width: 50%;
      text-align: right;
      word-break: break-all;
      float: right;
      color: #333333;
    }
  }

  hr {
    margin: 8% 0;
    border: 1px solid #f5f5f5;
  }

  p:last-child {
    margin: 4% 0;
  }

  .bottom {
    width: 92%;
    position: absolute;
    bottom: 5%;
    color: #999999;
    font-size: 1.2rem;
    height: 50px;
    line-height: 50px;

    button {
      float: right;
      width: 100%;
      height: 50px;
      border: none;
      outline: none;
      color: #ffffff;
      background-color: #ff9933;
      border-radius: 48px;
    }
  }
}
</style>
